import { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import InfiniteScroll from "react-infinite-scroll-component";
// components
import AiDesignBenchmarkProductCard from "components/atoms/AIDesign/AiDesignBenchmarkProductCard";
import NoSearchResult from "components/atoms/NoSearchResult";
import SmallSpinner from "components/atoms/SmallSpinner";
// hooks
import { AIImageSearchResultType } from "hooks/useAiImageSearch";
import useSourceMap from "hooks/useSourceMap";
// utils
import {
  convertStringToDateFormate,
  convertStringToTimestamp,
} from "utils/convertDate";
import { convertUSLocalString } from "utils/beautifyNumbers";
import { convertDate } from "utils/dates";

interface IBenchmarkProductsProps {
  productIds: string[];
  euclidianData: { [jbid: string]: any };
  benchmarkData?: AIImageSearchResultType;
  onSelectProduct: (product: any, pageNumber: number) => void;
  sortKeyword?: string;
  orderKeyword?: string;
  pageNumber?: number;
  isFilterOpen?: boolean;
  filterCount?: number;
}

export const BenchmarkProducts: React.FC<IBenchmarkProductsProps> = ({
  productIds,
  euclidianData,
  onSelectProduct,
  benchmarkData,
  sortKeyword = "similarity",
  orderKeyword = "desc",
  pageNumber,
  isFilterOpen = false,
  filterCount,
}) => {
  const { t } = useTranslation("common");
  const [step, setStep] = useState<number>(0);
  const allSourceMapData = useSourceMap();

  const data = useMemo(() => {
    const temp = Array.from(benchmarkData?.metadata || []);

    if (sortKeyword === "similarity") {
      let parsedData: { [key: string]: any } = {};
      temp.forEach((element) => {
        parsedData[element.jbid] = element;
      });

      const result = productIds.map((jbId) => parsedData[jbId]);

      if (orderKeyword === "desc") {
        return result;
      } else {
        return result.reverse();
      }
    }

    temp.sort((a, b) => {
      if (sortKeyword === "date") {
        const firstDate = convertStringToTimestamp(a.first_seen.toString());
        const secondDate = convertStringToTimestamp(b.first_seen.toString());
        return orderKeyword === "desc"
          ? secondDate - firstDate
          : firstDate - secondDate;
      } else if (sortKeyword === "price") {
        return orderKeyword === "desc"
          ? b.price_usd - a.price_usd
          : a.price_usd - b.price_usd;
      }
      return 0;
    });

    return temp;
  }, [benchmarkData, sortKeyword, orderKeyword, step]);

  useEffect(() => {
    if (pageNumber && step !== pageNumber) {
      setStep(pageNumber);
    }
  }, [pageNumber]);

  return (
    <div className={cn("flex flex-wrap gap-3 w-full")}>
      {productIds && productIds?.length > 0 ? (
        <div className="h-full w-full">
          <div
            id="scrollableDiv"
            className={cn(
              "overflow-auto pr-2 h-[calc(100vh-268px)]",
              filterCount === 1 && "!h-[calc(100vh-206px)]"
            )}
          >
            <InfiniteScroll
              next={() => {
                setStep(step + 1);
              }}
              hasMore={step * 20 < productIds.length}
              loader={
                <div className="w-full h-10 flex justify-center items-center">
                  <SmallSpinner
                    className="w-6 h-6 bg-white rounded-full"
                    svgClassName="!w-6 !h-6"
                  />
                </div>
              }
              dataLength={step * 20}
              className={cn(
                "grid xxs:grid-cols-2 md:!grid-cols-2 2md:!grid-cols-3 gap-3",
                !isFilterOpen && "md:!grid-cols-4 2md:!grid-cols-4"
              )}
              scrollableTarget="scrollableDiv"
            >
              {data &&
                data.slice(0, (step + 1) * 20)?.map((product, i) => {
                  let count = 0;
                  if (product?.restocks_8w) count = 8;
                  else if (product?.restocks_6w) count = 6;
                  else if (product?.restocks_4w) count = 4;
                  else if (product?.restocks_2w) count = 2;

                  return product ? (
                    <AiDesignBenchmarkProductCard
                      key={i}
                      containerClassName="!w-full"
                      jbid={product.jbid}
                      brandName={product.brand}
                      productName={product.name}
                      distance={euclidianData?.[product.jbid]?.distance}
                      caption={euclidianData?.[product.jbid]?.caption}
                      price={`${convertUSLocalString(
                        Math.floor(product.price)
                      )} ${product.currency}`}
                      retailer={
                        allSourceMapData?.[product.retailer]?.name || ""
                      }
                      spacing="mt-4"
                      minHeight={150}
                      className="hover:cursor-pointer"
                      time={""}
                      firstSeen={
                        !!product.first_seen
                          ? convertDate(
                              convertStringToDateFormate(
                                product.first_seen.toString()
                              ),
                              "MMM DD, YYYY"
                            )
                          : ""
                      }
                      onClick={() => {
                        onSelectProduct(product, step);
                      }}
                      tooltipClassName={
                        (i + 1) % (isFilterOpen ? 3 : 4) === 0
                          ? "right-[80%]"
                          : "left-[80%]"
                      }
                    />
                  ) : (
                    ""
                  );
                })}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center">
          <NoSearchResult
            isIcon
            title={t("nodata_benchmark")}
            titleClassName="!text-sm !font-semibold"
            description={t("nodata_benchmark_description")}
            descriptionClassName="!title-sm"
          />
        </div>
      )}
    </div>
  );
};
