import cn from "classnames";
import React from "react";
import { getProductImageUrl } from "utils/getProductImageUrl";
import { useImage } from "react-image";
import { capitalize } from "lodash";
import SkeletonLoading from "../SkeletonLoading";
import useTranslation from "next-translate/useTranslation";
import ImageResponsive from "../ImageResponsive";
import Tooltip from "../Tooltip";
import { convertDate } from "utils/dates";

interface AiDesignBenchmarkProductCardProps {
  jbid?: string;
  brandName?: string;
  productName?: string;
  image?: string;
  price?: string;
  retailer?: string;
  time?: string;
  spacing?: string;
  minHeight?: number;
  className?: string;
  distance?: number;
  caption?: string;
  firstSeen?: string;
  onClick?: () => void;
  tooltipClassName?: string;
  containerClassName?: string;
}

const AiDesignBenchmarkProductCard = ({
  jbid,
  retailer,
  brandName,
  productName,
  image,
  price,
  className,
  firstSeen,
  onClick,
  tooltipClassName,
  containerClassName,
}: AiDesignBenchmarkProductCardProps) => {
  const { t, lang } = useTranslation("common");
  const imageUrl = getProductImageUrl(brandName, productName, jbid);

  const { src, isLoading } = useImage({
    srcList: [image?.toString() || imageUrl || "", "/images/no_image.png"],
    useSuspense: false,
  });

  return (
    <Tooltip
      content={t(`launch_date_with_value`, {
        firstSeen: firstSeen
          ? lang !== "cn"
            ? firstSeen
            : t("year_month_day", {
                year: convertDate(firstSeen, "YYYY"),
                month: convertDate(firstSeen, "MM"),
                day: convertDate(firstSeen, "DD"),
              })
          : "",
      })}
      className="top-[50%] w-[180px] bg-gray-90 text-white whitespace-nowrap"
      arrowHidden
    >
      <div
        className={cn(
          "bg-white rounded-2xl border border-border-normal w-[169px] card-shadow group relative",
          containerClassName
        )}
      >
        <div
          className={cn("py-2 px-4 w-full h-[140px] cursor-pointer", className)}
          onClick={onClick}
        >
          {!isLoading ? (
            <ImageResponsive
              url={src as string}
              alt=""
              referrerPolicy="no-referrer"
              className={cn(
                "mx-auto",
                src === "/images/no_image.png"
                  ? "mt-9"
                  : "h-[136px] object-contain"
              )}
            />
          ) : (
            <SkeletonLoading className="w-full h-full" />
          )}
        </div>
        <div className="relative text-body flex flex-col justify-start gap-0.5 pt-2 px-3 pb-3">
          <div className="text-[11px] font-semibold leading-tight truncate text-typography-title h-[15px]">
            {firstSeen &&
              (lang !== "cn"
                ? firstSeen
                : t("year_month_day", {
                    year: convertDate(firstSeen, "YYYY"),
                    month: convertDate(firstSeen, "MM"),
                    day: convertDate(firstSeen, "DD"),
                  }))}
          </div>
          <p className="text-[10px] font-normal leading-tight truncate text-typography-body-normal">
            {capitalize(productName)}
          </p>
          <div className="text-[10px] font-normal leading-tight truncate text-typography-body-normal">
            {brandName}
          </div>
          <p
            className={cn(
              "text-[10px] font-normal leading-tight truncate text-typography-body-normal"
            )}
          >
            {price} • {retailer}
          </p>
        </div>
      </div>
    </Tooltip>
  );
};

export default AiDesignBenchmarkProductCard;
