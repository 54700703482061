import { IProductDetailData } from "api/ProductApi";
import { ProductType } from "types";
import ExternalLinkIcon from "public/icons/external_link_18.svg";
import CustomButton from "components/atoms/CustomButton";
import useTranslation from "next-translate/useTranslation";
import ProductPriceAreaChart from "components/atoms/TrendChart/ProductPriceAreaChart";
import Scrollbar from "components/atoms/Scrollbar";
import { useMemo } from "react";
import { capitalize, upperFirst } from "lodash";
import {
  convertStringToDate,
  convertStringToTimestamp,
} from "utils/convertDate";
import { useGetProductPriceHistory } from "hooks/useGetProductPriceHistory";
import { getProductImageUrl } from "utils/getProductImageUrl";
import { Catgroup, Gender } from "utils/constants";
import ColorTile from "components/atoms/ColorTile";
import { displayDiscount } from "utils/prices";
import dayjs from "dayjs";
import SkeletonLoading from "components/atoms/SkeletonLoading";
import useSourceMap from "hooks/useSourceMap";
import BackButton from "./BackButton";
import { useGetProductDetail } from "hooks/useGetProductDetail";
import ImageResponsive from "components/atoms/ImageResponsive";
import { convertUSLocalString } from "utils/beautifyNumbers";

interface IProductDetailsProps {
  product: ProductType | IProductDetailData;
  onClose?: () => void;
}

const ProductDetails: React.FC<IProductDetailsProps> = ({
  product,
  onClose,
}) => {
  const { t } = useTranslation("common");

  const { data: detailsData = [], isLoading: isLoadingProductDetail } =
    useGetProductDetail(
      {
        jbid: product.jbid,
      },
      {
        enabled: (product.jbid?.length || 0) > 0,
      }
    );

  const productData = detailsData?.[0];

  const imageUrl = getProductImageUrl(
    product.brand,
    product.name,
    product.jbid
  );

  const allSourceMapData = useSourceMap();

  const { data: priceHistory, isLoading: isLoadingPriceHistory } =
    useGetProductPriceHistory({
      jbid: product.jbid,
    });

  const restockValues = [
    { value: productData?.restocks_8w, weeks: 8 },
    { value: productData?.restocks_6w, weeks: 6 },
    { value: productData?.restocks_4w, weeks: 4 },
    { value: productData?.restocks_2w, weeks: 2 },
  ];

  const { weeks } = restockValues.find(({ value }) => value) || { weeks: 0 };

  const materialValues = useMemo(() => {
    const { material } = product || {};
    const matArray = material?.mat?.[0]?.l || [];
    const materialStrings = matArray.map((item: any) => {
      const { v } = material?.mat[0].comp?.[item] || {};
      return `${capitalize(t(item))} ${v || ""}%`;
    });
    const materialString = materialStrings.join(", ");
    return materialString || "-";
  }, [product]);

  const restockTime = useMemo(() => {
    return weeks ? t("restock_time", { weeks }) : "-";
  }, [weeks, t]);

  const { categories = [], chartData = [] } = useMemo(() => {
    if (priceHistory) {
      let categories = priceHistory[product.jbid].map((el) =>
        convertStringToTimestamp(el.updated)
      );
      let chartData = priceHistory[product.jbid].map((el) => el.price);

      if (categories.length === 1) {
        categories = [categories[0], new Date().getTime()];
        chartData = [chartData[0], chartData[0]];
      }

      return {
        categories,
        chartData,
      };
    }
    return {
      categories: [],
      chartData: [],
    };
  }, [priceHistory]);

  return (
    <div className="xxs:pb-4 md:!pb-0">
      <div className="flex items-center justify-between w-full">
        <BackButton onClick={onClose} />
        <CustomButton
          icon={ExternalLinkIcon}
          label={t("open_retailer_website")}
          variant="secondary"
          buttonTextClassName="xxs:!text-xs xs:!text-sm"
          className="md:!py-2 md:!px-4 xxs:px-2 xxs:py-1"
          onClick={() => {
            if (productData) {
              const productUrl = `https://www.jellibeans.com/products/${productData.brand.replace(
                " ",
                "-"
              )}-${allSourceMapData[productData?.retailer]?.name.replace(
                " ",
                "-"
              )}-${productData.jbid}`;
              window.open(productUrl);
            }
          }}
        />
      </div>
      <Scrollbar className="h-[calc(100%-69px)]">
        <div className="xxs:p-0 xxs:mt-4 md:!mt-0 md:!p-8">
          <div className="flex xxs:flex-col md:!flex-row items-stretch gap-4">
            <div className="md:!w-1/2 xxs:w-full">
              <div className="xxs:w-full md:!w-[300px] h-80 mx-auto">
                <ImageResponsive
                  url={imageUrl}
                  className="w-full h-full mx-auto object-contain"
                />
              </div>
            </div>
            <div className="md:!w-1/2 w-full">
              <div>
                {isLoadingProductDetail ? (
                  <SkeletonLoading className="w-[200px] h-[22.5px]" />
                ) : (
                  <h3 className="text-lg leading-tight font-bold text-title">
                    {t(productData?.brand)}
                  </h3>
                )}
                {isLoadingProductDetail ? (
                  <SkeletonLoading className="w-[200px] h-4 mt-2" />
                ) : (
                  <p className="text-sm leading-tight font-medium text-title first-letter:uppercase">
                    {t(productData?.name)}
                  </p>
                )}
              </div>
              {isLoadingProductDetail ? (
                <SkeletonLoading className="w-[170px] h-12 mt-4" />
              ) : (
                <div className="xxs:text-2xl md:!text-40 leading-none font-normal text-title mt-4">
                  {productData?.price ? (
                    <>
                      <span>
                        {convertUSLocalString(Math.floor(productData?.price))}{" "}
                        {productData?.currency}
                      </span>
                      <span>*</span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              )}
              {!isLoadingProductDetail && (
                <div className="mt-4">
                  <h4 className="text-sm font-medium leading-tight text-title">
                    {t("at_retailer_name", {
                      retailer_name:
                        allSourceMapData[productData?.retailer]?.name,
                    })}
                  </h4>
                  <p className="text-xs leading-normal font-normal text-gray-60">
                    {t("price_change_description")}
                  </p>
                </div>
              )}
              <div className="mt-7 grid gap-4 xxs:grid-cols-2 xs:grid-cols-1">
                <div className="grid gap-4 justify-start xxs:grid-cols-1 xs:grid-cols-3">
                  <div className="xxs:w-fit md:!w-full xxs:py-0 xs:py-4">
                    <div className="text-sm leading-normal font-normal text-title">
                      {capitalize(t("category"))}
                    </div>
                    {isLoadingProductDetail ? (
                      <SkeletonLoading className="w-[100px] h-4 mt-4" />
                    ) : (
                      <div className="text-base leading-tight font-normal text-title mt-1 first-letter:uppercase">
                        {productData
                          ? t(
                              Catgroup[
                                productData?.catgroup as keyof typeof Catgroup
                              ]
                            )
                          : ""}
                      </div>
                    )}
                  </div>
                  <div className="xxs:w-fit md:!w-full xxs:py-0 xs:py-4">
                    <div className="text-sm leading-normal font-normal text-title">
                      {capitalize(t("gender"))}
                    </div>
                    {isLoadingProductDetail ? (
                      <SkeletonLoading className="w-[100px] h-4 mt-4" />
                    ) : (
                      <div className="text-base leading-tight font-normal text-title mt-1 first-letter:uppercase">
                        {productData
                          ? t(
                              Gender[
                                productData?.gender as unknown as keyof typeof Gender
                              ]
                            )
                          : ""}
                      </div>
                    )}
                  </div>
                  <div className="xxs:w-fit md:!w-full xxs:py-0 xs:py-4">
                    <div className="text-sm leading-normal font-normal text-title">
                      {capitalize(t("subcategory"))}
                    </div>
                    {isLoadingProductDetail ? (
                      <SkeletonLoading className="w-[100px] h-4 mt-4" />
                    ) : (
                      <div className="text-base leading-tight font-normal text-title mt-1 first-letter:uppercase">
                        {t(productData?.subcat || "")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid gap-4 xxs:grid-cols-1 xs:grid-cols-3 justify-start">
                  <div className="xxs:w-fit md:!w-full xxs:py-0 xs:py-4">
                    <div className="text-sm leading-normal font-normal text-title">
                      {capitalize(t("color"))}
                    </div>
                    {isLoadingProductDetail ? (
                      <SkeletonLoading className="w-[100px] h-4 mt-4" />
                    ) : (
                      <div className="flex items-center">
                        <ColorTile
                          color={productData?.colormain || ""}
                          className="mr-1"
                        />
                        <span className="text-base leading-tight font-normal text-title mt-1 first-letter:uppercase">
                          {t(productData?.colormain || "")}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="xxs:w-fit md:!w-full xxs:py-0 xs:py-4">
                    <div className="text-sm leading-normal font-normal text-title">
                      {capitalize(t("materials"))}
                    </div>
                    {isLoadingProductDetail ? (
                      <SkeletonLoading className="w-[100px] h-4 mt-4" />
                    ) : (
                      <div className="text-base leading-tight font-normal text-title">
                        {materialValues}
                      </div>
                    )}
                  </div>
                  <div className="xxs:w-fit md:!w-full xxs:py-0 xs:py-4">
                    <div className="text-sm leading-normal font-normal text-title">
                      {upperFirst(t("first_seen_date"))}
                    </div>
                    {isLoadingProductDetail ? (
                      <SkeletonLoading className="w-[100px] h-4 mt-4" />
                    ) : (
                      <div className="text-base leading-tight font-normal mt-1 text-title">
                        {productData?.first_seen
                          ? convertStringToDate(
                              productData?.first_seen,
                              "MMM DD, YYYY"
                            )
                          : "-"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid gap-4 xxs:col-span-2 xs:col-span-1 xxs:grid-cols-2 xs:grid-cols-3 justify-start">
                  <div className="xxs:w-fit md:!w-full xxs:py-0 xs:py-4">
                    <div className="text-sm leading-normal font-normal text-title">
                      {capitalize(t("restock"))}
                    </div>
                    {isLoadingProductDetail ? (
                      <SkeletonLoading className="w-[100px] h-4 mt-4" />
                    ) : (
                      <div className="text-base leading-tight font-normal text-title mt-1 first-letter:uppercase">
                        {restockTime}
                      </div>
                    )}
                  </div>
                  <div className="xxs:w-fit md:!w-full xxs:py-0 xs:py-4">
                    <div className="text-sm leading-normal font-normal text-title">
                      {upperFirst(t("last_markdown"))}
                    </div>
                    {isLoadingProductDetail ? (
                      <SkeletonLoading className="w-[100px] h-4 mt-4" />
                    ) : (
                      <div className="text-base leading-tight font-normal text-title">
                        {product
                          ? `${displayDiscount(
                              productData?.price_og,
                              productData?.price
                            )} , ${dayjs(new Date()).format("MMM DD, YYYY")}`
                          : "-"}
                      </div>
                    )}
                  </div>
                  <div className="w-full"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <h3 className="text-lg leading-tight font-bold text-heading">
              {upperFirst(t("price_history"))}
            </h3>
            <div className="mt-6">
              {isLoadingPriceHistory ? (
                <SkeletonLoading className="w-full h-[300px]" />
              ) : (
                <div className="border border-gray-40 rounded-md pt-12">
                  <ProductPriceAreaChart
                    categories={categories}
                    chartData={[
                      {
                        name: t("Price"),
                        type: "area",
                        data: chartData,
                        legend: {
                          show: true,
                        },
                      },
                    ]}
                    height={280}
                    currency={productData?.currency}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};

export default ProductDetails;
